import { Injectable } from '@angular/core';
import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState
} from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import {
	HubEvents,
	NotificationEvents
} from 'app/common/notification.constants';
import { catchAppError } from 'app/common/utils/utils.error';
import { filterNullOrUndefined } from 'app/extensions/pipe-operators';
import { selectToken } from 'app/store/account/account.selectors';
import { IAccountState } from 'app/store/account/account.state';
import { environment } from 'environments/environment';
import { from, Observable } from 'rxjs';
import { NotificationSubscriptionModel } from 'app/models/dto/notification-subscription';
import { isNullOrUndefined } from 'app/common/utils/utils.object';
import { selectAuth0Token } from 'app/store/auth0/auth0.selectors';
import { IdentityProviders } from 'app/common/identity-providers';

@Injectable({
	providedIn: 'root'
})
export class SignalRService {
	public disabled: boolean = true;
	private enableLogging: boolean = true;
	private hubConnection: HubConnection;
	private afterConnected: Promise<HubConnectionState>;

	private subscriptionCache: NotificationSubscriptionModel[];

	private readonly defaultTimeout: number = 5000;

	constructor(private readonly store: Store<IAccountState>) {
		this.subscriptionCache = [];

		const tokenSelector =
			environment.identityProvider == IdentityProviders.Auth0
				? selectAuth0Token
				: selectToken;

		this.afterConnected = new Promise<HubConnectionState>(
			(resolve, reject) => {
				this.store
					.select(tokenSelector)
					.pipe(filterNullOrUndefined())
					.subscribe(token => {
						if (this.disabled) {
							return;
						}

						if (
							isNullOrUndefined(this.hubConnection)
							|| this.hubConnection?.state
								== HubConnectionState.Disconnected
						) {
							this.hubConnection = this.init(token);
							this.connect()
								.then(() =>
									resolve(HubConnectionState.Connected)
								)
								.catch(e => reject(e));
						}
					});
			}
		);
	}

	public subscribe(groups: NotificationSubscriptionModel[]): void {
		if (this.disabled) {
			return;
		}

		this.subscriptionCache = this.subscriptionCache.concat(groups);

		from(this.afterConnected).subscribe(() => {
			if (this.enableLogging) {
				console.log(HubEvents.Subscribe, groups);
			}

			this.hubConnection
				.send(HubEvents.Subscribe, groups)
				.catch(catchAppError);
		});
	}

	public unsubscribe(groups: NotificationSubscriptionModel[]): void {
		if (this.disabled) {
			return;
		}

		groups.forEach(
			c =>
				(this.subscriptionCache = this.subscriptionCache.filter(
					x =>
						x.group !== c.group
						&& (isNullOrUndefined(c.entityId)
							|| x.entityId !== c.entityId)
				))
		);

		from(this.afterConnected).subscribe(() => {
			if (this.enableLogging) {
				console.log(HubEvents.UnSubscribe, groups);
			}

			this.hubConnection
				.send(HubEvents.UnSubscribe, groups)
				.catch(catchAppError);
		});
	}

	public onEvent<T>(name: NotificationEvents): Observable<T> {
		if (this.disabled) {
			return;
		}

		return new Observable<T>(observer => {
			return from(this.afterConnected).subscribe(() => {
				this.hubConnection.on(name, (notification: T) => {
					if (this.enableLogging) {
						console.log(name, notification);
					}

					return observer.next(notification);
				});
			});
		});
	}

	private init(token: string): HubConnection {
		return new HubConnectionBuilder()
			.withUrl(environment.signalrHubServiceUrl + '/hub', {
				accessTokenFactory: () => token
			})
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: context =>
					this.retryPolicy(context.previousRetryCount)
			})
			.build();
	}

	private connect(): Promise<HubConnectionState> {
		return this.hubConnection
			.start()
			.then(() => {
				console.log('Connected to notification hub');

				this.hubConnection.onreconnected(() => this.onReconnected());

				return HubConnectionState.Connected;
			})
			.catch(error => {
				console.log('Error while establishing connection', error);

				setTimeout(() => {
					this.connect();
				}, this.defaultTimeout);

				return HubConnectionState.Disconnected;
			});
	}

	private onReconnected(): void {
		console.log('Reconnected to notification hub');

		if (this.enableLogging) {
			console.log(HubEvents.Subscribe, this.subscriptionCache);
		}

		this.hubConnection.send(HubEvents.Subscribe, this.subscriptionCache);
	}

	private retryPolicy(previousRetryCount: number): number {
		switch (previousRetryCount) {
			case 0:
				return 1000;

			case 1:
				return 2000;

			case 2:

			case 3:

			case 4:

			case 5:
				return this.defaultTimeout;

			default:
				return this.defaultTimeout * 6;
		}
	}
}
