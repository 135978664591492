export enum PermissionScopes {
	Users = 'users',
	Roles = 'roles',
	Report = 'report',
	Customers = 'customers',
	CustomerRetentionRates = 'customerRetentionRates',
	CustomerNotes = 'customerNotes',
	CustomerMonthlyData = 'customerMonthlyData',
	CustomerCustomDateOfService = 'customerCustomDateOfService',
	MarkCustomerMonthlySubmissionAsReady = 'markCustomerMonthlySubmissionAsReady',
	CustomerThirdPartyAssociation = 'customerThirdPartyAssociation',
	CustomerBdc = 'customerBdc',
	Storage = 'storage',
	Audit = 'auditLog',
	DataLoadingHistory = 'dataLoadingStatistic',
	Claim = 'receipt',
	DealerGroups = 'dealerGroups',
	ReinsuranceAgreements = 'reinsuranceAgreements',
	Hangfire = 'hangfire',
	LoadedDealerData = 'loadedDealerData',
	ApproveLoadedDealerData = 'approveLoadedDealerData',
	Ndc = 'ndc',
	FormularyNdc = 'formularyNdc',
	RebateAggregators = 'rebateAggregators',
	RebateAggregatorFormularies = 'rebateAggregatorFormularies',
	RebatesLoad = 'rebatesLoad',
	RebatesLoadMl = 'rebatesLoadMl',
	RebatesLoadExtraDelete = 'rebatesLoadExtraDelete',
	RebatesLoadChangeSubmissionPeriod = 'rebatesLoadChangeSubmissionPeriod',
	RebatesUpdate = 'rebatesUpdate',
	Payor = 'payor',
	RejectsLoad = 'rejectsLoad',
	LineOfBusiness = 'lineOfBusiness',
	LineOfBusinessSubtype = 'lineOfBusinessSubtype',
	ThirdParty = 'thirdParty',
	RebateLoadRevalidateFileRecords = 'rebateLoadRevalidateFileRecords',
	NdcReplacementMappings = 'ndcReplacementMappings',
	DownstreamClientIdAssociation = 'downstreamClientIdAssociation',
	SubmissionPeriod = 'submissionPeriod',
	RebateAggregatorNdcRates = 'rebateAggregatorNdcRates',
	AberrantQuantity = 'aberrantQuantity',
	UnbreakableQuantity = 'unbreakableQuantity',
	RebateGroup = 'rebateGroup',
	RebateGroupRule = 'rebateGroupRule',
	RebateGroupFormulary = 'rebateGroupFormulary',
	ExcludedPharmacy = 'excludedPharmacy',
	InOutpatientDefinition = 'inOutpatientDefinition',
	NonPatientExclusion = 'nonPatientExclusion',
	AuditEvents = 'auditEvents',
	RebateLoadRollbackFile = 'rebateLoadRollbackFile',
	DataUnarchive = 'dataUnarchive',
	RebateLoadConversionAudit = 'rebateLoadConversionAudit'
}
