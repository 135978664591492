import { NgClass, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
	generatedFileUpdatePeriod,
	listItemTooltipShowDelay
} from 'app/common/app-constants';
import { NotificationGroups } from 'app/common/notification.constants';
import { isNullOrUndefined } from 'app/common/utils/utils.object';
import { filterNullOrUndefined } from 'app/extensions/pipe-operators';
import { GeneratedFileButtonComponent } from 'app/layout/common/generated-files/generated-file-button/generated-file-button.component';
import { GeneratedFileModel } from 'app/models/api/generated-file-model';
import { GeneratedFileTypeModel } from 'app/models/api/generated-file-type-model';
import { NgForTrackByIdDirective } from 'app/shared/directives/track-by/track-by-id.directive';
import { EnumTranslatePipe } from 'app/shared/pipes/enum-translate.pipe';
import {
	deleteGeneratedFile,
	downloadGeneratedFile,
	loadCurrentUserGeneratedFile,
	subscribeToGroups,
	unSubscribeFromGroups
} from 'app/store/account/account.actions';
import {
	selectCurrentUser,
	selectGeneratedFile
} from 'app/store/account/account.selectors';
import { IAccountState } from 'app/store/account/account.state';
import { BaseApiComponent } from 'app/common/base/base-api-component';
import { NotificationSubscriptionModel } from 'app/models/dto/notification-subscription';
import { buildEntityGroups } from 'app/common/utils/utils.notification.groups';
import { filter, first, interval, map, startWith } from 'rxjs';

@UntilDestroy()
@Component({
	selector:        'app-generated-files',
	templateUrl:     './generated-files.component.html',
	encapsulation:   ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports:         [
		MatDivider,
		MatIcon,
		MatIconButton,
		MatMenu,
		TranslocoPipe,
		MatMenuTrigger,
		MatTooltip,
		NgForTrackByIdDirective,
		EnumTranslatePipe,
		GeneratedFileButtonComponent,
		NgIf,
		NgClass,
		MatBadge
	],
	standalone: true
})
export class GeneratedFilesComponent
	extends BaseApiComponent
	implements OnInit, OnDestroy
{
	@ViewChild('userFilesMenuTrigger') userFilesMenuTrigger: MatMenuTrigger;

	public readonly GeneratedFileTypeModel = GeneratedFileTypeModel;
	public readonly listItemTooltipShowDelay: number = listItemTooltipShowDelay;

	public file: GeneratedFileModel;
	public fileExists: boolean = false;

	public groups: NotificationSubscriptionModel[];

	constructor(
		private readonly store: Store<IAccountState>,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly ngZone: NgZone
	) {
		super();
	}

	ngOnInit(): void {
		this.ngZone.runOutsideAngular(() => {
			interval(generatedFileUpdatePeriod)
				.pipe(startWith(0), untilDestroyed(this))
				.subscribe(() => {
					this.store.dispatch(
						loadCurrentUserGeneratedFile(
							this.createRequestPayload()
						)
					);
				});
		});

		this.store
			.select(selectCurrentUser)
			.pipe(
				filterNullOrUndefined(),
				map(({ id }) => id),
				first()
			)
			.subscribe((userId: number) => {
				this.groups = buildEntityGroups(
					[NotificationGroups.GeneratedFile],
					userId
				);

				this.store.dispatch(
					subscribeToGroups(this.createRequestPayload(this.groups))
				);
			});

		this.store
			.select(selectGeneratedFile)
			.pipe(
				untilDestroyed(this),
				filter(
					(x: GeneratedFileModel) =>
						isNullOrUndefined(x)
						|| !this.fileExists
						|| this.file.id !== x.id
						|| this.file.status !== x.status
				)
			)
			.subscribe((x: GeneratedFileModel) => {
				this.file = x;
				this.fileExists = !isNullOrUndefined(x);

				const menuOpened: boolean = this.userFilesMenuTrigger?.menuOpen;

				if (this.fileExists) {
					if (!menuOpened) {
						this.userFilesMenuTrigger?.openMenu();
					}
				} else if (menuOpened) {
					this.userFilesMenuTrigger?.closeMenu();
				}

				this.changeDetectorRef.detectChanges();
			});
	}

	ngOnDestroy(): void {
		this.store.dispatch(
			unSubscribeFromGroups(this.createRequestPayload(this.groups))
		);

		super.ngOnDestroy();
	}

	public download(): void {
		this.store.dispatch(
			downloadGeneratedFile(this.createRequestPayload(this.file.id))
		);
	}

	public delete(): void {
		this.store.dispatch(deleteGeneratedFile(this.createRequestPayload()));
	}

	public cancel(): void {
		this.userFilesMenuTrigger.closeMenu();
	}
}
