import { Injectable } from '@angular/core';
import { AccountInfoModel } from 'app/models/api/account-info-model';
import { Observable } from 'rxjs';
import { UpdateAccountModel } from 'app/models/api/update-account-model';
import { UserModel } from 'app/models/api/user-model';

import { ProfileRoutes } from '../api-routes';
import { HttpApiService } from '../base/http-api.service';
import { BaseApiCRUDService } from '../base/base-api-crud-service';

@Injectable()
export class ProfileApiService extends BaseApiCRUDService<
	number,
	void,
	UserModel,
	void,
	UpdateAccountModel,
	void,
	void,
	void
> {
	protected rootRoute: string = ProfileRoutes.Root;

	constructor(protected httpApiService: HttpApiService) {
		super(httpApiService);
	}

	public getCurrentUser(
		cancellationSubject: Observable<void>
	): Observable<UserModel> {
		return this.httpApiService.get<UserModel>(
			this.rootRoute,
			null,
			cancellationSubject
		);
	}

	public getCurrentUserInfo(
		cancellationSubject: Observable<void>
	): Observable<AccountInfoModel> {
		return this.httpApiService.get<AccountInfoModel>(
			`${this.rootRoute}/${ProfileRoutes.Info}`,
			null,
			cancellationSubject
		);
	}

	public checkUsersPasswordResetRequired(): Observable<boolean> {
		return this.httpApiService.get<boolean>(
			`${this.rootRoute}/${ProfileRoutes.PasswordResetRequired}`
		);
	}
}
