import { Location } from '@angular/common';
import { Injectable, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AccountRoutes } from 'app/common/routes';
import { catchAppError } from 'app/common/utils/utils.error';
import { filterNullOrUndefined } from 'app/extensions/pipe-operators';
import { AuthorizedAccountModel } from 'app/models/api/authorized-account-model';
import { CodeVerificationModel } from 'app/models/api/code-verification-model';
import { CurrentUserPasswordResetVerifyCodeModel } from 'app/models/api/current-user-password-reset-verify-code-model';
import { ForgotPasswordRequest } from 'app/models/api/forgot-password-request';
import { MfaChallengeModel } from 'app/models/api/mfa-challenge-model';
import { PasswordResetConfirmationModel } from 'app/models/api/password-reset-confirmation-model';
import { SendPasswordResetVerifyCodeModel } from 'app/models/api/send-password-reset-verify-code-model';
import { SignInModel } from 'app/models/api/sign-in-model';
import { TokenRequest } from 'app/models/api/token-request';
import { UpdateAccountModel } from 'app/models/api/update-account-model';
import { GeneratedFileApiService } from 'app/services/api/admin/generated-file-api.service';
import { AuthApiService } from 'app/services/api/auth-api.service';
import { PasswordApiService } from 'app/services/api/password-api.service';
import { ProfileApiService } from 'app/services/api/admin/profile-api.service';
import {
	catchError,
	combineLatestWith,
	map,
	mergeMap,
	of,
	switchMap,
	tap,
	withLatestFrom
} from 'rxjs';
import { SignalRService } from 'app/services/notifications/base/signalr-service';
import { NotificationSubscriptionModel } from 'app/models/dto/notification-subscription';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

import {
	AccountActions,
	loadCurrentUserGeneratedFile,
	setPermissions,
	setTokens
} from './account.actions';
import { selectAuthenticatedRedirectUrlOrNoPermissions } from './account.selectors';
import { AuthStatus, IAccountState } from './account.state';

@Injectable()
export class AccountEffects {
	signIn$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.SignIn),
			mergeMap(
				({
					cancellationObservable,
					data: { signInModel }
				}: ActionRequestPayload<{
					signInModel: SignInModel;
					rememberMe: boolean;
				}>) => {
					const request = this.authApiService.challenge(
						signInModel,
						cancellationObservable
					);

					return request.pipe(
						map((data: MfaChallengeModel) => {
							if (data.passwordResetRequired === true) {
								return {
									type: AccountActions.CheckUsersPasswordResetRequired,
									data
								};
							}

							if (!data.mfaRequired) {
								return {
									type: AccountActions.GetToken,
									data: data.accessKey
								};
							}

							this.router
								.navigate(
									[
										AccountRoutes.SignIn,
										AccountRoutes.VerifyCode
									],
									{
										queryParams: {
											accessKey: data.accessKey
										}
									}
								)
								.catch(catchAppError);

							return {
								type: AccountActions.MfaVerificationStarted,
								data
							};
						}),
						catchError(() =>
							of({ type: AccountActions.SignInFailed })
						)
					);
				}
			)
		)
	);

	verificationStarted$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.GetToken),
			mergeMap(
				({
					data,
					cancellationObservable
				}: ActionRequestPayload<string>) => {
					const request = this.authApiService.getToken(
						data,
						cancellationObservable
					);

					return request.pipe(
						map(account => ({
							type: AccountActions.TokenUploaded,
							data: account
						})),
						catchError(() =>
							of({ type: AccountActions.VerificationFailed })
						)
					);
				}
			)
		)
	);

	tokenUploaded$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.TokenUploaded),
			mergeMap(
				({
					data: { token, refreshToken, permissions }
				}: ActionResponsePayload<AuthorizedAccountModel>) => {
					this.store.dispatch(setTokens({ token, refreshToken }));
					this.store.dispatch(setPermissions({ permissions }));

					return of({ type: AccountActions.AuthorizationSucceeded });
				}
			)
		)
	);

	resendSignIn$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.ResendSignIn),
			mergeMap(
				({
					cancellationObservable,
					data
				}: ActionRequestPayload<TokenRequest>) => {
					const request = this.authApiService.resend(
						data,
						cancellationObservable
					);

					return request.pipe(
						map(mfa => ({
							type: AccountActions.MfaVerificationStarted,
							data: mfa
						})),
						catchError(() => {
							this.router
								.navigate([AccountRoutes.SignIn])
								.catch(catchAppError);

							return of({ type: AccountActions.SignInFailed });
						})
					);
				}
			)
		)
	);

	verifyCode$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.VerifyCode),
			mergeMap(
				({
					cancellationObservable,
					data
				}: ActionRequestPayload<CodeVerificationModel>) => {
					const request = this.authApiService.verifyCode(
						data,
						cancellationObservable
					);

					return request.pipe(
						map(account => ({
							type: AccountActions.CodeVerified,
							data: account
						})),
						catchError(() =>
							of({ type: AccountActions.VerificationFailed })
						)
					);
				}
			)
		)
	);

	codeVerified$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.CodeVerified),
			mergeMap(
				({
					data: { token, refreshToken, permissions }
				}: ActionResponsePayload<AuthorizedAccountModel>) => {
					this.store.dispatch(setTokens({ token, refreshToken }));
					this.store.dispatch(setPermissions({ permissions }));

					return of({ type: AccountActions.AuthorizationSucceeded });
				}
			)
		)
	);

	authSucceeded$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AccountActions.AuthorizationSucceeded),
				combineLatestWith(
					this.store
						.select(selectAuthenticatedRedirectUrlOrNoPermissions)
						.pipe(filterNullOrUndefined())
				),
				map(([_, redirectUrl]) => redirectUrl),
				tap(redirectUrl => {
					this.router.navigate([redirectUrl]).catch(catchAppError);
				})
			),
		{ dispatch: false }
	);

	checkUsersPasswordResetRequired$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AccountActions.CheckUsersPasswordResetRequired),
				tap(
					({
						data: { accessKey, userId }
					}: ActionResponsePayload<MfaChallengeModel>) => {
						this.router
							.navigate([AccountRoutes.ConfirmPasswordReset], {
								queryParams: {
									key: accessKey,
									userId
								}
							})
							.catch(catchAppError);
					}
				)
			),
		{ dispatch: false }
	);

	usersPasswordResetRequiredChecked$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.UsersPasswordResetRequiredChecked),
			mergeMap(({ data }: ActionResponsePayload<boolean>) =>
				of({
					type: data
						? AccountActions.RequirePasswordReset
						: AccountActions.AuthorizationSucceeded
				})
			),
			catchError(() => of({ type: AccountActions.ErrorOccurred }))
		)
	);

	signOut$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.SignOut),
			map(() => {
				const redirectUrl = this.location.path();

				this.router
					.navigate([AccountRoutes.SignOut])
					.catch(catchAppError);

				return {
					type: AccountActions.SetRedirectUrl,
					data: redirectUrl
				};
			})
		)
	);

	refreshToken$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.RefreshToken),
			mergeMap(() => {
				const request = this.authApiService.refreshToken();

				return request.pipe(
					map(data => ({
						type: AccountActions.RefreshTokenSucceeded,
						data
					})),
					catchError(() =>
						of({ type: AccountActions.RefreshTokenFailed })
					)
				);
			})
		)
	);

	refreshTokenSucceeded$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AccountActions.RefreshTokenSucceeded),
				tap(
					({
						data: { token, refreshToken, permissions }
					}: ActionResponsePayload<AuthorizedAccountModel>) => {
						this.store.dispatch(setTokens({ token, refreshToken }));
						this.store.dispatch(setPermissions({ permissions }));
					}
				)
			),
		{ dispatch: false }
	);

	refreshTokenFailed$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.RefreshTokenFailed),
			map(() => {
				this.router
					.navigate([AccountRoutes.SignIn])
					.catch(catchAppError);

				const redirectUrl = this.location.path();

				return {
					type: AccountActions.SetRedirectUrl,
					data: redirectUrl
				};
			})
		)
	);

	sendForgotPasswordRequest$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.SendForgotPasswordRequest),
			mergeMap(
				({
					cancellationObservable,
					data
				}: ActionRequestPayload<ForgotPasswordRequest>) => {
					const request =
						this.passwordApiService.sendForgotPasswordRequest(
							data,
							cancellationObservable
						);

					return request.pipe(
						map(() => ({
							type: AccountActions.ForgotPasswordRequestSent
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	forgotPasswordRequestSent$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AccountActions.ForgotPasswordRequestSent),
				tap(() => {
					this.router
						.navigate([AccountRoutes.SignIn])
						.catch(catchAppError);
				})
			),
		{ dispatch: false }
	);

	sendPasswordResetVerifyCode$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.SendPasswordResetVerifyCode),
			withLatestFrom(this.store$),
			mergeMap(
				([{ cancellationObservable, data }, state]: [
					ActionRequestPayload<SendPasswordResetVerifyCodeModel>,
					{ account: IAccountState }
				]) => {
					const request =
						state.account.status === AuthStatus.Authenticated
							? this.passwordApiService.sendAuthenticatedUserPasswordResetVerifyCode(
									data,
									cancellationObservable
								)
							: this.passwordApiService.sendPasswordResetVerifyCode(
									data,
									cancellationObservable
								);

					return request.pipe(
						map(() => ({
							type: AccountActions.PasswordResetVerifyCodeSent
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	sendAuthenticatedUserPasswordResetVerifyCode$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.SendAuthenticatedUserPasswordResetVerifyCode),
			mergeMap(
				({
					cancellationObservable,
					data
				}: ActionRequestPayload<CurrentUserPasswordResetVerifyCodeModel>) => {
					const request =
						this.passwordApiService.sendAuthenticatedUserPasswordResetVerifyCode(
							data,
							cancellationObservable
						);

					return request.pipe(
						map(() => ({
							type: AccountActions.AuthenticatedUserPasswordResetVerifyCodeSent
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	confirmPasswordReset$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.ConfirmPasswordReset),
			withLatestFrom(this.store$),
			mergeMap(
				([{ cancellationObservable, data }, state]: [
					ActionRequestPayload<PasswordResetConfirmationModel>,
					{ account: IAccountState }
				]) => {
					const request =
						state.account.status === AuthStatus.Authenticated
							? this.passwordApiService.confirmCurrentUserPasswordReset(
									data,
									cancellationObservable
								)
							: this.passwordApiService.confirmPasswordReset(
									data,
									cancellationObservable
								);

					return request.pipe(
						map(resetResult => ({
							type: AccountActions.PasswordResetConfirmed,
							data: resetResult
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	passwordResetConfirmed$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AccountActions.PasswordResetConfirmed),
				withLatestFrom(this.store$),
				tap(
					([
						_,
						{
							account: { status }
						}
					]: [
						ActionRequestPayload<PasswordResetConfirmationModel>,
						{ account: IAccountState }
					]) => {
						const route =
							status === AuthStatus.Authenticated
								? AccountRoutes.Profile
								: AccountRoutes.SignIn;

						this.router.navigate([route]).catch(catchAppError);
					}
				)
			),
		{ dispatch: false }
	);

	sendCurrentUserChangePasswordRequest$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.ResetCurrentUserPasswordRequest),
			switchMap(
				({
					data,
					cancellationObservable
				}: ActionRequestPayload<number>) => {
					const request =
						this.passwordApiService.sendCurrentUserPasswordChangeRequest(
							data,
							cancellationObservable
						);

					return request.pipe(
						map(() => ({
							type: AccountActions.CurrentUserPasswordReseted
						})),
						catchError(() =>
							of({
								type: AccountActions.ErrorOccurred
							})
						)
					);
				}
			)
		)
	);

	sendUserChangePasswordRequest$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.ResetUserPasswordRequest),
			mergeMap(
				({
					data,
					cancellationObservable
				}: ActionRequestPayload<string>) => {
					const request =
						this.passwordApiService.sendUserPasswordChangeRequest(
							data,
							cancellationObservable
						);

					return request.pipe(
						map(() => ({
							type: AccountActions.UserPasswordReseted
						})),
						catchError(() =>
							of({
								type: AccountActions.ErrorOccurred
							})
						)
					);
				}
			)
		)
	);

	loadCurrentUserGeneratedFiles$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.LoadCurrentUserGeneratedFile),
			mergeMap(
				({ cancellationObservable }: ActionRequestPayload<void>) => {
					const request = this.generatedFileApiService.getUserFile(
						cancellationObservable
					);

					return request.pipe(
						map(data => ({
							type: AccountActions.CurrentUserGeneratedFileLoaded,
							data
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	downloadGeneratedFile$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.DownloadGeneratedFile),
			mergeMap(
				({
					data,
					cancellationObservable
				}: ActionRequestPayload<number>) => {
					const request = this.generatedFileApiService.downloadFile(
						data,
						cancellationObservable
					);

					return request.pipe(
						map(data => ({
							type: AccountActions.GeneratedFileDownloaded,
							data
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	deleteGeneratedFile$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.DeleteGeneratedFile),
			mergeMap((payload: ActionRequestPayload<void>) => {
				const request = this.generatedFileApiService.deleteFile(
					payload.cancellationObservable
				);

				return request.pipe(
					map(data => ({
						type: AccountActions.GeneratedFileDeleted,
						data
					})),
					tap(() => {
						this.store.dispatch(
							loadCurrentUserGeneratedFile(payload)
						);
					}),
					catchError(() => of({ type: AccountActions.ErrorOccurred }))
				);
			})
		)
	);

	loadCurrentUser$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.LoadCurrentUser),
			mergeMap(
				({ cancellationObservable }: ActionRequestPayload<void>) => {
					const request = this.profileApiService.getCurrentUser(
						cancellationObservable
					);

					return request.pipe(
						map(data => ({
							type: AccountActions.CurrentUserLoaded,
							data
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	loadCurrentUserInfo$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.LoadCurrentUserInfo),
			mergeMap(
				({ cancellationObservable }: ActionRequestPayload<void>) => {
					const request = this.profileApiService.getCurrentUserInfo(
						cancellationObservable
					);

					return request.pipe(
						map(data => ({
							type: AccountActions.CurrentUserInfoLoaded,
							data
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	saveCurrentUserInfo$ = createEffect(() =>
		this.actions.pipe(
			ofType(AccountActions.SaveCurrentUserInfo),
			mergeMap(
				({
					data,
					cancellationObservable
				}: ActionRequestPayload<UpdateAccountModel>) => {
					const request = this.profileApiService.update(
						data,
						cancellationObservable
					);

					return request.pipe(
						map(() => ({
							type: AccountActions.CurrentUserInfoSaved,
							data
						})),
						catchError(() =>
							of({ type: AccountActions.ErrorOccurred })
						)
					);
				}
			)
		)
	);

	subscribeToGroup$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AccountActions.SubscribeToGroups),
				tap(
					({
						data
					}: ActionRequestPayload<
						NotificationSubscriptionModel[]
					>) => {
						this.signalrService.subscribe(data);
					}
				)
			),
		{ dispatch: false }
	);

	unSubscribeFromGroup$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AccountActions.UnsubscribeFromGroups),
				tap(
					({
						data
					}: ActionRequestPayload<
						NotificationSubscriptionModel[]
					>) => {
						this.signalrService.unsubscribe(data);
					}
				)
			),
		{ dispatch: false }
	);

	constructor(
		private readonly actions: Actions,
		private readonly store: Store<IAccountState>,
		private readonly store$: Store<{ account: IAccountState }>,
		private readonly router: Router,
		private readonly location: Location,
		private readonly authApiService: AuthApiService,
		private readonly passwordApiService: PasswordApiService,
		private readonly generatedFileApiService: GeneratedFileApiService,
		private readonly profileApiService: ProfileApiService,
		private readonly signalrService: SignalRService
	) {}
}

export const accountEffectsProviders: Provider[] = [
	AuthApiService,
	PasswordApiService,
	GeneratedFileApiService,
	ProfileApiService
];
