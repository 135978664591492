import { createAction, props } from '@ngrx/store';
import { CheckResultModel } from 'app/models/api/check-result-model';
import { CustomerInOutpatientDefinitionModel } from 'app/models/api/customer-in-outpatient-definition-model';
import { CustomerInOutpatientDefinitionSearchFilterModel } from 'app/models/api/customer-in-outpatient-definition-search-filter-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { InOutpatientDefinitionModel } from 'app/models/api/in-outpatient-definition-model';
import { InOutpatientDefinitionSearchFilterModel } from 'app/models/api/in-outpatient-definition-search-filter-model';
import { InOutpatientDefinitionSearchModel } from 'app/models/api/in-outpatient-definition-search-model';
import { InOutpatientDefinitionUpdateModel } from 'app/models/api/in-outpatient-definition-update-model';
import { InOutpatientDefinitionsUpdateModel } from 'app/models/api/in-outpatient-definitions-update-model';
import { SearchResponse } from 'app/models/api/search-response';
import { UpdateFilteredInOutpatientDefinitionsModel } from 'app/models/api/update-filtered-in-outpatient-definitions-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum InOutpatientDefinitionActions {
	LoadInOutpatientDefinitions = '[InOutpatientDefinition] LoadInOutpatientDefinitions',
	InOutpatientDefinitionsLoaded = '[InOutpatientDefinition] InOutpatientDefinitionsLoaded',

	LoadInOutpatientDefinitionsTotalCount = '[InOutpatientDefinition] LoadInOutpatientDefinitionsTotalCount',
	InOutpatientDefinitionsTotalCountLoaded = '[InOutpatientDefinition] InOutpatientDefinitionsTotalCountLoaded',

	PreLoadInOutpatientDefinition = '[InOutpatientDefinition] PreLoadInOutpatientDefinition',
	InOutpatientDefinitionPreLoaded = '[InOutpatientDefinition] InOutpatientDefinitionPreLoaded',
	PreLoadedInOutpatientDefinitionNotFound = '[InOutpatientDefinition] PreLoadedInOutpatientDefinitionNotFound',

	LoadInOutpatientDefinition = '[InOutpatientDefinition] LoadInOutpatientDefinition',
	InOutpatientDefinitionLoaded = '[InOutpatientDefinition] InOutpatientDefinitionLoaded',
	LoadedInOutpatientDefinitionNotFound = '[InOutpatientDefinition] LoadedInOutpatientDefinitionNotFound',

	UpdateInOutpatientDefinition = '[InOutpatientDefinition] UpdateInOutpatientDefinition',
	InOutpatientDefinitionUpdated = '[InOutpatientDefinition] InOutpatientDefinitionUpdated',

	UpdateInOutpatientDefinitions = '[InOutpatientDefinition] UpdateInOutpatientDefinitions',
	InOutpatientDefinitionsUpdated = '[InOutpatientDefinition] InOutpatientDefinitionsUpdated',

	CheckInOutpatientDefinitionUpdateLoading = '[InOutpatientDefinition] CheckInOutpatientDefinitionUpdateLoading',
	InOutpatientDefinitionUpdateLoadingChecked = '[InOutpatientDefinition] InOutpatientDefinitionUpdateLoadingChecked',

	InOutpatientDefinitionsBulkOperationFinished = '[InOutpatientDefinition] InOutpatientDefinitionsBulkOperationFinished',

	StartInOutpatientDefinitionsUpdate = '[InOutpatientDefinition] StartInOutpatientDefinitionsUpdate',
	InOutpatientDefinitionsUpdateStarted = '[InOutpatientDefinition] InOutpatientDefinitionsUpdateStarted',

	LoadCustomerInOutpatientDefinitions = '[InOutpatientDefinition] LoadCustomerInOutpatientDefinitions',
	CustomerInOutpatientDefinitionsLoaded = '[InOutpatientDefinition] CustomerInOutpatientDefinitionsLoaded',

	ErrorOccurred = '[InOutpatientDefinition] ErrorOccurred'
}

export const loadInOutpatientDefinitions = createAction(
	InOutpatientDefinitionActions.LoadInOutpatientDefinitions,
	props<
		ActionRequestPayload<
			FilterSearchRequest<InOutpatientDefinitionSearchFilterModel>
		>
	>()
);

export const inOutpatientDefinitionsLoaded = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionsLoaded,
	props<
		ActionResponsePayload<SearchResponse<InOutpatientDefinitionSearchModel>>
	>()
);

export const loadInOutpatientDefinitionsTotalCount = createAction(
	InOutpatientDefinitionActions.LoadInOutpatientDefinitionsTotalCount,
	props<
		ActionRequestPayload<
			FilterSearchRequest<InOutpatientDefinitionSearchFilterModel>
		>
	>()
);

export const inOutpatientDefinitionsTotalCountLoaded = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionsTotalCountLoaded,
	props<ActionResponsePayload<number>>()
);

export const preLoadInOutpatientDefinition = createAction(
	InOutpatientDefinitionActions.PreLoadInOutpatientDefinition,
	props<ActionRequestPayload<number>>()
);

export const inOutpatientDefinitionPreLoaded = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionPreLoaded,
	props<ActionResponsePayload<InOutpatientDefinitionModel>>()
);

export const loadInOutpatientDefinition = createAction(
	InOutpatientDefinitionActions.LoadInOutpatientDefinition,
	props<ActionRequestPayload<number>>()
);

export const inOutpatientDefinitionLoaded = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionLoaded,
	props<ActionResponsePayload<InOutpatientDefinitionModel>>()
);

export const loadedInOutpatientDefinitionNotFound = createAction(
	InOutpatientDefinitionActions.LoadedInOutpatientDefinitionNotFound
);

export const loadCustomerInOutpatientDefinitions = createAction(
	InOutpatientDefinitionActions.LoadCustomerInOutpatientDefinitions,
	props<
		ActionRequestPayload<
			FilterSearchRequest<CustomerInOutpatientDefinitionSearchFilterModel>
		>
	>()
);

export const customerInOutpatientDefinitionsLoaded = createAction(
	InOutpatientDefinitionActions.CustomerInOutpatientDefinitionsLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<CustomerInOutpatientDefinitionModel>
		>
	>()
);

export const updateInOutpatientDefinition = createAction(
	InOutpatientDefinitionActions.UpdateInOutpatientDefinition,
	props<ActionRequestPayload<InOutpatientDefinitionUpdateModel>>()
);

export const inOutpatientDefinitionUpdated = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionUpdated
);

export const updateInOutpatientDefinitions = createAction(
	InOutpatientDefinitionActions.UpdateInOutpatientDefinitions,
	props<ActionRequestPayload<InOutpatientDefinitionsUpdateModel>>()
);

export const inOutpatientDefinitionsUpdated = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionsUpdated
);

export const checkInOutpatientDefinitionUpdateDisabled = createAction(
	InOutpatientDefinitionActions.CheckInOutpatientDefinitionUpdateLoading,
	props<ActionRequestPayload<void>>()
);

export const inOutpatientDefinitionUpdateLoadingChecked = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionUpdateLoadingChecked,
	props<ActionRequestPayload<CheckResultModel>>()
);

export const inOutpatientDefinitionsBulkOperationFinished = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionsBulkOperationFinished
);

export const startInOutpatientDefinitionsUpdate = createAction(
	InOutpatientDefinitionActions.StartInOutpatientDefinitionsUpdate,
	props<ActionRequestPayload<UpdateFilteredInOutpatientDefinitionsModel>>()
);

export const inOutpatientDefinitionsUpdateStarted = createAction(
	InOutpatientDefinitionActions.InOutpatientDefinitionsUpdateStarted
);

export const errorOccurred = createAction(
	InOutpatientDefinitionActions.ErrorOccurred
);
