<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<app-vertical-navigation
	#nav
	class="dark bg-primary-500 print:hidden"
	[mode]="isScreenSmall ? 'over' : 'side'"
	name="mainNavigation"
	[navigation]="navigationItems | async"
	[opened]="!isScreenSmall"
>
	<!-- Navigation header hook -->
	<ng-container fuseVerticalNavigationContentHeader>
		<!-- Logo -->
		<div class="flex h-10 flex-row bg-white py-0.5">
			<!-- Logo -->
			<img
				class="mr-8 pr-8 drop-shadow-md"
				src="./assets/logo/TWRX_logo_primary.svg"
				alt="TWRX logo"
			/>
		</div>
	</ng-container>
</app-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
	<!-- Header -->
	<div class="relative z-49 flex h-10 w-full flex-0 items-center bg-white pl-3 pr-4 shadow print:hidden">
		<!-- Navigation toggle button -->
		<button
			mat-icon-button
			(click)="toggleNavigation('mainNavigation')"
		>
			<mat-icon
				fontSet="fas"
				fontIcon="fa-bars"
				class="text-xl"
			></mat-icon>
		</button>

		<!-- Logo -->
		<div class="h-10 bg-white pl-1 pt-2">
			<img
				*ngIf="!nav.opened"
				@fadeInLeft
				class="w-24 drop-shadow-md"
				src="./assets/logo/TWRX_logo_primary.svg"
				alt="TWRX logo"
			/>
		</div>

		<!-- Menu -->
		<div class="ml-auto flex flex-row gap-x-2 pl-2">
			<!-- User Generated Files -->
			<app-generated-files></app-generated-files>

			<!-- User Profile -->
			<app-profile></app-profile>
		</div>
	</div>

	<!-- Content -->
	<div class="flex flex-auto flex-col">
		<router-outlet *ngIf="true"></router-outlet>
	</div>
</div>
